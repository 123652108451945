<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="articles"
                          base-table="article"
                          columnsPrefix="article.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :tbody-tr-class="rowClass"
                          :actions-list="getActions()"
                          title="article.title.articles"
                >
                    <template #cell(id)="row">
                        <a @click.prevent="openArticle(row.item)"
                           href="#">{{ row.item.id }}</a>
                    </template>
                    <template #cell(parent_id)="row">
                        <a @click.prevent="$root.$children[0].openModal('article-modal', {id: row.item.parent_id}, refreshTable)"
                           href="#">{{ row.item.parent_id }}</a>
                    </template>
                    <template #cell(config_json)="row">
                        <span :title="JSON.stringify(row.item.config_json)">
                            {{ row.item.config_json }}
                        </span>
                    </template>

                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item v-if="$root.hasAccess('articles','update')"
                                             @click="openArticle(row.item)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="$root.hasAccess('articles','delete')"
                                             @click="deleteArticle(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('events-modal', {id: row.item.id,type:'article'})"
                            >
                                <font-awesome-icon class="mr-2" icon="calendar"/>
                                {{ $t('common.button.events') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Articles',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticlesTable']),
        ...mapGetters('CommonData', ['getModules', 'getSettings']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Articles/fetchArticles', 'getArticlesTable');
        },
        deleteArticle(id) {
            this.$removeConfirm('Articles/deleteArticle', id, this.refreshTable)
        },
        openArticle(item) {
            if (this.getModules().includes('hotels')) {
                let modal = 'hotel-room-article-modal'
                let type = null

                if (item.category_ids && item.category_ids.contains(this.getSettings()['insurance_category'])) {
                    modal = 'service-article-modal'
                    type = 'insurance'
                }
                if (item.category_ids && item.category_ids.contains(this.getSettings()['transfer_category'])) {
                    modal = 'service-article-modal'
                    type = 'transfer'
                }
                if (item.category_ids && item.category_ids.contains(this.getSettings()['food_category'])) {
                    modal = 'service-article-modal'
                    type = 'food'
                }
                if (item.category_ids && item.category_ids.contains(this.getSettings()['booking_service_category'])) {
                    modal = 'service-article-modal'
                    type = 'service'
                }

                this.$root.$children[0].openModal(modal, {
                    id: item ? item.id : 0,
                    type: type
                }, this.refreshTable)
            } else {
                let modal = 'article-modal'

                if (this.getModules().includes('article-services') && item.category_id == this.getSettings()['booking_service_category']) {
                    modal = 'service-article-modal'
                } else if (this.getModules().includes('article-rooms')) {
                    modal = 'room-article-modal'
                }


                this.$root.$children[0].openModal(modal, {
                    id: item ? item.id : 0
                }, this.refreshTable)
            }
        },
        openServiceArticle(id, type) {
            this.$root.$children[0].openModal('service-article-modal', {
                id: id,
                type: type
            }, this.refreshTable)
        },
        openChangeAttribute() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'article',
                selectedData: this.getSelectedData('articles'),
            }, this.refreshTable, {width: '800px'})
        },
        openMassStockAssign() {
            this.$root.$children[0].openModal('mass-stock-assign-modal', {
                type: 'article',
                selectedData: this.getSelectedData('articles'),
            }, this.refreshTable, {width: '800px'})
        },
        openChangeConfigurableAttribute() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'article_attributes',
                selectedData: this.getSelectedData('articles'),
            }, this.refreshTable, {width: '800px'})
        },
        openChangeConfigurableProperties() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'article_properties',
                selectedData: this.getSelectedData('articles'),
            }, this.refreshTable, {width: '800px'})
        },
        openImport() {
            this.$root.$children[0].openModal('import-modal', {type: 'article'}, this.refreshTable, {width: '800px'})
        },
        openImportPrices() {
            this.$root.$children[0].openModal('import-modal', {type: 'articlePrices'}, this.refreshTable, {width: '800px'})
        },
        deleteSelected() {
            this.$removeConfirmAuth('Articles/deleteArticles', this.selected.map(row => row.id), this.refreshTable)
        },
        rowClass(item) {
            if (item) {
                let row_class = '';
                for (let stock of item.stocks) {
                    if (stock.items_cnt_avail <= stock.warning_lvl) {
                        row_class = 'table-danger';
                        break;
                    }
                }
                return row_class;
            }
        },
        getActions() {
            let actions = []
            if (this.getModules().includes('hotels')) {
                actions = [
                    {
                        title: this.$t('article.button.create_room'),
                        icon: 'plus',
                        click: () => {
                            this.openArticle({})
                        }
                    },
                    {
                        title: this.$t('article.button.create_service'),
                        icon: 'plus',
                        click: () => {
                            this.openServiceArticle(null, 'service')
                        }
                    },
                    {
                        title: this.$t('article.button.create_food_article'),
                        icon: 'plus',
                        click: () => {
                            this.openServiceArticle(null, 'food')
                        }
                    },
                    {
                        title: this.$t('article.button.create_transfer_article'),
                        icon: 'plus',
                        click: () => {
                            this.openServiceArticle(null, 'transfer')
                        }
                    },
                    {
                        title: this.$t('article.button.create_insurance_article'),
                        icon: 'plus',
                        click: () => {
                            this.openServiceArticle(null, 'insurance')
                        }
                    },
                    {
                        title: this.$t('article.button.import_prices'),
                        icon: 'download',
                        click: () => {
                            this.openImportPrices()
                        }
                    },
                ]
            } else {
                actions = [
                    {
                        title: this.$t('common.button.create'),
                        icon: 'plus',
                        hide: !this.$root.hasAccess('articles', 'create'),
                        click: () => {
                            this.openArticle({})
                        }
                    },
                    {
                        title: this.$t('common.button.create_service'),
                        icon: 'plus',
                        hide: !this.getModules().includes('article-services'),
                        click: () => {
                            this.openServiceArticle(null)
                        }
                    },
                ]
            }

            actions.push({
                title: this.$t('article.button.change_attribute'),
                disabled: !(this.selected.length || this.selectedSource != 'selected'),
                icon: 'list',
                hide: !this.$root.hasAccess('articles', 'update'),
                click: () => {
                    this.openChangeAttribute()
                }
            })

            if (!this.$root.simpleMode) {
                actions.push({
                    title: this.$t('article.button.change_configurable_attribute'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('articles', 'update'),
                    click: () => {
                        this.openChangeConfigurableAttribute()
                    }
                })

                actions.push({
                    title: this.$t('article.button.change_configurable_properties'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('articles', 'update'),
                    click: () => {
                        this.openChangeConfigurableProperties()
                    }
                })

                actions.push({
                    title: this.$t('article.button.assign_stock'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('articles', 'update'),
                    click: () => {
                        this.openMassStockAssign()
                    }
                })
            }

            actions.push({
                title: this.$t('article.button.delete_selected'),
                icon: 'list',
                hide: !this.$root.hasAccess('articles', 'delete'),
                disabled: !this.selected.length,
                click: () => {
                    this.deleteSelected()
                }
            })
            actions.push({
                title: this.$t('common.button.import'),
                icon: 'download',
                hide: !this.$root.hasAccess('articles', 'create'),
                click: () => {
                    this.openImport()
                }
            })

            return actions
        }
    }
}
</script>